<template>
  <div class="myNewsCenter">
    <!-- 消息中心 -->
    <van-nav-bar
      title="消息中心"
      left-text
      left-arrow
      @click-left="onClickLeft"
      :fixed="true"
    />
    <!-- <van-search v-model="searchVal" placeholder="请输入搜索关键词" @input="onSearch" /> -->
    <div class="search">
      <form action="/">
        <van-search
          v-model="searchVal"
          shape="round"
          :placeholder="'搜索'"
          @search="onSearch"
        />
      </form>
    </div>
    <div class="content">
      <van-tabs v-model="active" color="#2B8DF0" @change="getAllMsg">
        <van-tab title="未读信息"></van-tab>
        <van-tab title="已读信息"></van-tab>
      </van-tabs>
      <van-pull-refresh v-model="isLoadingAwait" @refresh="getAllMsg">
        <van-list
          v-model="loadingAwait"
          :finished="finishedAwait"
          finished-text="没有更多了"
          offset="200"
          @load="getAllMsg"
        >
          <van-cell
            class="ass_cell"
            v-for="(item, index) in assessList"
            :key="index"
            center
            :label="item.crdate"
            @click="goToDetails(item)"
          >
            <template #title>
              <div class="news_title">{{ item.wxtitle }}</div>
            </template>
          </van-cell>
        </van-list>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
import { getAlarmMsgList, getAlarmMsgDetail } from '@api/wx.js'
export default {
  data() {
    return {
      isLoadingAwait: false,
      loadingAwait: false,
      finishedAwait: false,
      active: 0,
      userInfo: JSON.parse(localStorage.userInfo) || {},
      assessList: [],
      assessList2: [],
      searchVal: '',
      V3url: './'
    }
  },
  created() {
    // this.getAllMsg()
  },
  watch: {
    searchVal(n) {
      this.onSearch()
    }
  },
  methods: {
    onClickLeft() {
      this.$router.push('/home')
    },
    // 获取所有记录
    getAllMsg() {
      this.isLoadingAwait = true
      getAlarmMsgList({
        isread: this.active == 1,
        empid: this.userInfo.empid
      }).then(res => {
        this.isLoadingAwait = false
        this.loadingAwait = true
        this.finishedAwait = true
        this.assessList = res.data
        this.assessList2 = this.assessList.filter(item => true)
      })
    },
    // 跳转详情页
    goToDetails(item) {
      if (item.url) {
        getAlarmMsgDetail({
          autoid: item.autoid
        }).then(res => {
          let url = ''
          let u = item.url
          if (u.indexOf('http') > -1) {
            url = u
          } else {
            url = this.V3url + u
          }
          if (
            url.indexOf('yidongqiandao') > -1 ||
            (url.indexOf('weixinlocal') == -1 &&
              url.indexOf('dingdinglocal') == -1)
          ) {
            window.location.href = url
          } else {
            this.$router.push({
              path: '/jump',
              query: { url: url, time: new Date().getTime() }
            })
          }
        })
      } else {
        const from = 'center'
        this.$router.push({
          path: '/msgDetails',
          query: { autoid: item.autoid, from: from }
        })
      }
    },
    onSearch() {
      if (this.searchVal) {
        this.assessList = this.assessList2.filter(
          item => item.wxtitle.indexOf(this.searchVal) > -1
        )
      } else {
        this.assessList = this.assessList2.filter(item => true)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.myNewsCenter {
  height: 100vh;
  overflow: hidden;
  .van-nav-bar {
    background: #2b8df0;

    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
  }
  .van-search {
    margin-top: 1.22667rem;
  }
  .content {
    // margin-top: 1.22667rem;
    overflow: auto;
    height: calc(100% - 92px);
    /deep/.van-tabs {
      .van-tab {
        .van-tab__text {
          font-size: 32px;
          font-family: Source Han Sans CN;
          font-weight: bold;
        }
      }
    }
    .ass_cell {
      /deep/.news_img {
        .van-image__img {
          border-radius: 8px;
        }
      }
      .van-cell__title {
        margin-left: 16px;
        .news_title {
          // line-height: 1.5;
          font-size: 28px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #333333;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }
        .van-cell__label {
          text-align: right;
          font-size: 24px;
          font-family: Helvetica Neue;
          font-weight: bold;
          color: #999999;
        }
      }
    }
  }
}
</style>
